/* eslint-disable max-len */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from 'src/app/core/interfaces/environment';

/**
 * DEV environment
 */
export const environment: Environment = {
  production: false,
  logStorageOnStartup: true,
  //apiBaseUrl: 'http://192.168.3.99:3200/api',
  apiBaseUrl: 'https://api.lswr-guia.com/api',
  assetsBaseUrl: 'https://api.lswr-guia.com/',
  dbName: '__App_Skel_DEV_DB',
  // eslint-disable-next-line max-len
  emailRegex: new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i),
  passwordRegex: new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,30}$/),
  phoneRegex: new RegExp(/^\+?\d*$/),
  birthDateRegex: new RegExp(/^(0?[1-9]|[12][\d]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/),
  contactUrl: 'https://desk.zoho.com/support/fbw?formType=AdvancedWebForm&fbwId=edbsnd5666806546a25ba6e444756384cf09e6a594e7bd9521c65fa0322cf70a4abf7&xnQsjsdp=edbsnf5b147bf53dcdb6066ceff8796a7036f&mode=showNewWidget&displayType=iframe',
  appId: 'd60b5f44a0c8',
  appName: 'UrologuIA',
  maxScreenWidth: 1100,
  sourcesUrl: "https://uroweb.org/guidelines"

};
