import * as moment from 'moment';
import { Device } from '@capacitor/device';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StorageKey } from 'src/app/core/interfaces/storage';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * Locale interface
 */
export interface Locale {
  /**
   * Defines a string variable to manage the lang field
   */
  lang: string;

  /**
   * Defines an any variable to manage the data field
   */
  data: any;
}

/**
 * Translate Service
 */
@Injectable({
  providedIn: 'root',
  // eslint-disable-next-line indent
})
export class TranslationService {
  /**
   * Defines an any Array variable to manage the lang identifies
   */
  private readonly langIds: any = [];
  public lang;
  /**
   * Service Constructor
   *
   * @param storageService
   * @param translate
   */
  constructor(
    private readonly platform: Platform,
    private readonly storageService: StorageService,
    private readonly translate: TranslateService,
  ) { }

  /**
   * Initialize
   */
  init(): void {
    // add new langIds to the list
    this.translate.addLangs(['es, en']);
    // if (this.platform.is('capacitor')) {
    //   // get language from the device
    //   this.lang = Device.getLanguageCode().then((lang) => {
    //     console.log('got language code', lang.value);
    //     this.setLanguage(lang.value.match(/es|en/) ? lang.value.split('-')[0] : 'es');
    //   }, (err) => {
    //     this.setLanguage('es');
    //   });
    // } else {
    // In order to set language from browser, use navigator.language
    this.setLanguage('es');
    // }
    //  moment.locale('es');
  }

  /**
   * Load Translation
   *
   * @param args: Locale[]
   */
  loadTranslations(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach(locale => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);

      this.langIds.push(locale.lang);
    });

    // add new languages to the list
    this.translate.addLangs(this.langIds);
  }

  /**
   * Setup language
   *
   * @param lang: any
   */
  async setLanguage(lang): Promise<void> {
    if (lang) {
      // page freezes when using the temporary Lang drop-down
      // this.translate.use(this.translate.getDefaultLang());
      this.translate.use(lang);
      moment.locale(lang);
      await this.storageService.set(StorageKey.language, lang);
    }
  }

  /**
   * Returns selected language
   */
  async getSelectedLanguage(): Promise<string> {
    return await this.storageService.get(StorageKey.language) || this.translate.getDefaultLang();
  }
}
